<template>
  <div class="container">
    <!-- 返回头部 -->
    <el-backtop></el-backtop>

    <!-- 头部区域 -->
    <Header :headerData={} />

    <div class="panel">

      <div class="info">
        <H2>Database construction on planktons and biofouling organisms</H2>
        <div class="">
          <p>
            We focused on the plankton and biofouling biomasses in Tashue Bay (Hong Kong Ocean Park), Shenzhen Bay, the Lamma Island, and the Swires Institute of Marine Science Laboratory. Photo IDs, classifications, dates, locations, samplers, and magnifications of the 684 pictures were recorded. They were ordered in groups by names. Rarefaction curves and frequency charts were created to record biodiversity. We concluded that Taishue Bay (Hong Kong) has more plankton diversity than Shenzhen Bay, and the SWIMS sea region has higher biofouling diversity than Lamma Island fish farm. The database could serve as the basis for the development of auto-detection by artificial intelligence.
          </p>
          <p>
            Download excel file for detail information about the database, or visit STEAM projects database 
            <el-button plain icon="el-icon-medal-1" size="medium" @click="clickLogin">Entry >></el-button>
          </p>        
          <el-button type="primary" icon="el-icon-download" size="mini" el-icon--right @click="click2DownLoad(1)">PDF</el-button>
        </div>

      <!-- <div class="" v-for="d, i in db" :key="i">
        <div class="sect-desc">
          <h4>{{d.path}}</h4> 
        </div>
        <div class="imagePanel">
          <div class="box-card" v-for="r, j in d.item" :key="j">
            <div class="txt-box">
              <div class="txt">
                <div class="img-box">
                  <el-image
                    :src="host_path + ''+ r"
                    :fit="'contain'"
                    lazy
                    @click="imgViewDialog_show(r)"
                  >
                  </el-image>
                </div>        
                {{r.replace('/static/img/','')}}
              </div>
              <div class="bottom">
              </div>
            </div>
          </div>
        </div>
      </div> -->


    <!-- 大图弹出式窗口 -->
    <el-dialog
      class="imgView-dialog"
      :visible.sync="imgViewDialogVisible"
      :modal="true"
      width="90%">
        <div slot="placeholder" class="image-slot">            
          <i class="el-icon-loading"></i>加载中<span class="dot">...</span>
        </div>
        <div>
          <!-- <a :href='"https://www.daphina.net/ImageJ?open=" + imgData.path'  target="_blank">进入ImageJ编辑,第一次加载时间较长，请耐心等候 >></a> -->
        </div>
        <el-image
          :fit="'contain'"
          :src="imgViewDialog_imgSrc">
        </el-image>
    </el-dialog>


      <!-- Part: 8 -->
      <!-- <el-row justify="center">
        <div class="contact-us">
          <h3>JOURNAL CONTACT LIST</h3>
          <h4>High School Student Submissions: info@ysassociation.org</h4>
          <p>Editor: Y. Martin Lo, Biointellipro, martin@ysassociation.org</p>
          <p>Editor: Dr. Tim, Biointellipro, tim@ysassociation.org</p>
          <p>Editor: Dr. Tom, Artificial intelligence, tom@ysassociation.org</p>
        </div>
      </el-row> -->

    </div>
 
    </div>
  
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
  import Header from '../components/Header.vue'
  import Footer from "../components/Footer.vue";
 
  export default {
    name: "Home",
    data() {
      return {
        imgViewDialogVisible: false,     // 大图弹出式窗口
        imgViewDialog_imgSrc: "",        // 大图文件路径
        host_path : '',
        // db : [
        //   {
        //     path: '\\Diarmuid_LammaIsland_HK_photo\\BioFoul',
        //     item : [
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D10_CAR_230704_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D11_CHI_230704_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D12_POL_230704_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D13_CHI_230704_NY_S.png'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D14_SPO_230704_NY_S.png'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D15_SPO_230704_NY_S.png'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D16_SCU_230704_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D17_SST_BRI_230704_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D18_UN_A_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D19_BRO_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D1_ASC_STY_230704_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D20_BRO_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D21_POL_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D22_POL_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D23_POL_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D24_FLA_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D25_BAR_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D26_SHR_GAM_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D27_FLA_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D28_POL_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D29_POL_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D2_MUS&BAR_230704_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D30_SHR_GAM_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D31_CHI_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D32_SST_BRI_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D33_POL_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D34_FLA_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D35_FLA_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D36_POL_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D37_SCU_230706_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D3_TUB_230704_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D4_TUB_230704_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D5_CAR_230704_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D6_ANP_230704_230704_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D7_ANP_230704_230704_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D8_POL_230704_NY_S.jpg'),
        //       require('../assets/database/Diarmuid_LammaIsland_HK_photo/BioFoul/D9_SST_BRI_230704_NY_S.jpg')
        //     ],
        //   },
        //   {
        //     path: '\\Diarmuid_Oceanpark_HK_photo\\Sea_Park',
        //     item : [
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D10_ALG_DIA_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D11_ALG_DIA_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D12_ALG_GRE_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D13_ALG_DIA_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D14_ALG_DIA_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D15_ALG_PED_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D16_ALG_DIA_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D17_ALG_DIA_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D18_ALG_DIA_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D19_ALG_DIA_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D1_ALG_DIA_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D20_ALG_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D21_COP_L_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D22_ALG_DIA_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D23_ALG_DIA_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D24_ALG_DIA_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D25_ALG_DIA_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D26_ALG_CER_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D27_ALG_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D28_ALG_CER_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D28_ALG_GRE_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D29_ALG_DIA_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D2_ALG_DIA_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D30_UN_A_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D31_ALG_CER_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D31_ALG_DIA_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D32_COP_L_&ROT_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D33_ALG_GRE_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D34_ALG_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D35_ALG_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D36_ALG_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D37_ALG_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D38_COP_L_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D39_UN_B_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D3_ALG_DIA_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D40_ALG_DIA_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D41_ALG_GRE_230706Z_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D4_ALG_DIA_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D5_COP_L_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D6_ALG_GRE_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D7_ALG_DIA_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D8_ALG_DIA_230706_SP_S.jpg'),
        //       require('../assets/database/Diarmuid_Oceanpark_HK_photo/Sea_Park/D9_ALG_DIA_230706_SP_S.jpg'),
        //     ],
        //   },
        //   {
        //     path: '\\Diarmuid_SummerCamp_SZ_photo',
        //     item : [
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/15_COP_FA-CYC_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/16_COP_FA-CYC_230628_DS_S_10X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/17_COP_FA-HAR_230628_DS_S_10X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/18_COP_FA-HAR_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/19_COP_L_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/20_COP_L_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/21_COP_FA-CYC_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/22_COP_L_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/23_COP_FA-CYC_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/24_COP_FA-CYC_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/25_COP_FA-CYC_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/26_COP_FA-HAR_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/27_COP_L_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/28_BAR_L_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/29_COP_FA-HAR_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/30_BAR_L_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/31_COP_FA-CYC_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/32_COP_L_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/33_COP_FA-HAR_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/34_UN_G_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/35_BAR_L_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/36_COP_FA-CYC_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/37_UN_H_230628_DS_S_10X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/38_BAR_L_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/39_ROT_SP-D_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/40_COP_L_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/41_COP_FA-CYC_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/42_COP_FA-CYC_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/43_ROT_SP-D_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/44_COP_L_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/45_COP_FA-CYC_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/46_COP_L_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/47_COP_FA-CYC_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/48_COP_FA-HAR_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/49_ROT_SP-D_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/50_COP_FA-HAR_230628_DS_S_4X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/51_ROT_SP-D_230628_DS_S_10X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/52_ROT_SP-E_230628_DS_S_10X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/53_COP_L_230628_DS_S_10X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/54_ROT_SP-D_230628_DS_S_10X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/55_ROT_SP-E_230628_DS_S_10X.jpg'),
        //       require('../assets/database/Diarmuid_SummerCamp_SZ_photo/65_COP_FA-CAL_230628_DS_S_LT_20X.jpg'),
        //     ]
        //   },
        //   {
        //     path: 'Harry_SummerCamp_SZ_photo',
        //     item : [
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/37_POL_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/37_ROT_SP-D_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/38_DAP_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/39_UN-H_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/40_POL_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/41_COP_POL_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/42_ROT-D_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/43_COP_LAR_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/44_COP_FA-CYC_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/44_COP_LAR_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/45_COP_LAR_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/46_POL_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/47_ROT_SP-D_230628_DS_S_LT_40X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/48_COP_FA-CYC_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/49_DAP_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/50_COP_FA-HAR_230628_DS_S_LT_40X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/50_DAP_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/51_COP_FA-CYC_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/52_COP_FA-CYC_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/53_COP_FA-CYC_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/54_COP_LAR_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/55_COP_FA-CYC_230628_DS_S_LT_40X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/56_COP_LAR_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/57_ROT_SP-D_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/58_COP_FA-CYC_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/59_COP_LAR_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/60_ROT_SP-D_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/61_COP_LAR_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/62_BAR_230628_DS_S_LT_40X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/63_COP_FA-CYC_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/64_COP_FA-HAR_230628_DS_S_LT_40X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/65_COP_FA-CAL_230628_DS_S_LT_4X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/66_COP_FA-CYC_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/67_ROT_SP-D_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/68_ROT_SP-D_230628_DS_S_LT_40X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/69_POL_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/70_ROT_SP-D_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/71_COP_FA-CYC_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/72_ROT_SP-D_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/73_COP_FA-CYC_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/74_COP_LAR_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/75_COP_LAR_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/76_COP_FA-CYC_230628_DS_S_LT_20X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/77_COP_FA-CYC_230628_DS_S_LT_40X.jpg'),
        //       require('../assets/database/Harry_SummerCamp_SZ_photo/Harry_SummerCamp_SZ_photo/230628/78_COP_FA-CYC_230628_DS_S_LT_20X.jpg'),
        //     ]
        //   },
        //   {
        //     path: '\\HarryorSusanaorDiarmuid_Summercamp_SZ_photo',
        //     item : [
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/11_ROT_SP-C_230626_DS_S_20X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/12_ROT_SP-D_230626_DS_S_40X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/13_UN-A_230626_DS_S_10X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/14_GRE_GE-PED_230626_DS_S_40X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/15_GRE_GE-PED_230626_DS_S_40X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/16_ROT_SP-E_230626_DS_S_40X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/17_DIA_230626_DS_S_40X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/1_GRE_GE-PED_230626_DS_S_40X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/22_COP_2300626_DS_S_20X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/23_UN_C_230626_DS_S_20X.jpg'),
        //       // require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/24_POL_230626_DS_.tif'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/25_POL_230626_DS_S40X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/26_UN_D_230626_DS_S_20X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/27_UN_E_230626_DS_S_20X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/28_UN_F_230626_DS_S_20X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/29_UN_G_230626_DS_S_20X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/2_COP_230626_DS_S_20X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/30_GRE_PED_230626_DS_S_20X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/31_GRE_PED_230626_DS_S_20X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/32_ROT_230626_DS_S_20X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/34_ROT_230626_DS_S_20X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/35_ROT_230626_DS_S_20X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/36_ROT_230626_DS_S_20X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/3_DIA_230626_DS_S_40X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/4_Gre_GE-DIM_230626_DS_S_40X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/5_GRE_GE-PED_230626_DS_S_40X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/6_OST_230626_DS_S_20X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/7_Pol_230626_DS_S_20X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/8_POL_GRE_GE-DIM_230626_DS_S_20X.jpg'),
        //       require('../assets/database/HarryorSusanaorDiarmuid_Summercamp_SZ_photo/9_ROT_SP-A_230626_DS_S_20X.jpg'),
        //     ]
        //   },
        //   {
        //     path: 'Susana_SummerCamp_SZ_photo',
        //     item : [
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/10_COP_LAR_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/11_COP_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/12_COP_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/13_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/14_COP_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/15_SUS_COPEGG_DS_S_40X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/16_COP_BUTT_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/17_COP_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/19_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/1_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/20_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/21_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/22_COP_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/23_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/24_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/25_UN(EUK)_20230628_DS_S_40X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/26_UN(EUK)_20230628_DS_S_40X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/27_COP_LAR_20230628_DS_S_10X4.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/28_COP_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/29_COP_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/2_DAP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/30_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/31_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/32_COP_DEF_202308628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/33_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/34_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/35_ROT_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/36_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/37_COP_LAR_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/38_UN_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/39_COP_LAR_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/3_COP_LAR_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/40_DAP_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/42_COP_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/43_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/44_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/45_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/46_COP_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/47_COP_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/48_COP_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/4_ROT_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/50_COP_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/51_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/52_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/5_COP_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/6_COP_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/7_ROT_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/8_COP_LAR_20230628_DS_S_10X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/9_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/18_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/49_COP_20230628_DS_S_4X.jpg'),
        //       require('../assets/database/Susana_SummerCamp_SZ_photo/Susana_SummerCamp_SZ_photo/41_COP_20230628_DS_S_4X.jpg'),
        //     ]
        //   },
        // ]
      };
    },    
    components: {
      Footer,
      Header,
      // SectTurnBook,
    },

    mounted() {
      this.host_path = '';
    },

    methods: {
      click2DownLoad(i){
        // console.log(i)
        // window.location.href ='../../public/ysa_i1a' + (i+1) + '.pdf' ;
        window.location.href ='../../public/' + 'database_index.xlsx' ;
      },

      // 大图弹出图层
      imgViewDialog_show(filename) {
        console.log(filename)
        this.imgViewDialogVisible = true ;
        this.imgViewDialog_imgSrc = this.host_path + filename ;
      },
    }
  
  }

</script>


<style lang="less" scoped>
.container {
  // margin-bottom: 40px;
  padding-bottom: 0;
}
.panel{
  max-width: 1400px;
  margin: 100px auto;
  padding-left: 10px;
  text-align: left ;
  line-height: 1.75 ;
  .main-title {      
    font-size: 42px;
    font-weight: 700;
    letter-spacing: 0px;
  }
  > p{
    font-size: 19px;
    font-weight: 200;
  }
}
.sect-desc{
  text-align: left;
  line-height: 1.75 ;
  padding: 5px 20px;
  font-size: 19px;
  color:#333;
  p::before{
    content: '•';
    margin-right: 5px;
  }
}

.txt-link{
  font-size: 14px;
}
.txt-red{
  color: red;
}

.main {
  color: #333;
  padding: 0 10%;
  // 中区域的左侧文字区
}

.sect-title {
  max-width: 580px;
  text-align: center;
  margin: 24px auto;
  font-size: 26px;
  font-weight: bold;
}

.imagePanel{
      position:relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      .box-card {
        margin: 3px 5px auto;
        background-color: #ddd;
        border-radius: 5px;        
        .img-box {
          margin: 10px 10px auto;
          .el-image{
            width: 180px;
            height: 210px;
          }
        }
      }
      .txt-box {
        width: 180px;
        padding: 5px 10px;
        line-height: 1.2;
        .txt {
          font-size: 12px;
          // height: 40px;
          margin: 0;
          padding: 0;
          color: blue;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .bottom {
          margin-top: 2px;
          font-size: 12px;
          .icon{
            margin: 0 4px;
            color: red;
            font-weight: 550;
          }
          .icon:hover{
            cursor:pointer;
          }
        }
      }
    }



.contact-us {
  line-height: 1.0 ;
}

//显示时按钮样式
.el-button--primary { //需要更改的按钮类型
  background: #2836e0 !important;
  border-color: #2836e0 !important;
}
//移入时按钮样式
.el-button--primary:hover {
  background: #005eff !important;
  border-color: #005eff !important;
  color: #FFF !important;
}

@media (max-width: 550px) {
  .panel{
    margin: 50px 10px !important;
    h1{
      font-size: 36px !important;
    }
  }
}
</style>
